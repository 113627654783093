<template>
  <section>
    <CMSNavTop :apiLink="apiLink" />
    <CMSNavSidebar />
    <div style="padding-left: 56px">
      <v-container fluid>
        <v-row>
          <v-col md="4"
            ><v-card elevation="2"
              ><v-card-text class="text-h4 text-center text--primary"
                >Nowe zamówienia <br />
                <span class="green--text">{{ status.new }}</span></v-card-text
              ></v-card
            ></v-col
          >
          <v-col md="4"
            ><v-card elevation="2"
              ><v-card-text class="text-h4 text-center text--primary"
                >Zamówienia w trakcie <br />
                <span>{{ status.in_progress }}</span>
              </v-card-text></v-card
            ></v-col
          >
          <v-col md="4"
            ><v-card elevation="2"
              ><v-card-text class="text-h4 text-center text--primary"
                >Zrealizowane zamówienia <br />
                <span>{{ status.completed }}</span></v-card-text
              ></v-card
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <Chart :values="values" />
          </v-col>
          <v-col cols="12" md="4">
            <Logs :logs="logs" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
import CMSNavTop from "@/components/CMS/Navigations/CMSNavTop.vue";
import CMSNavSidebar from "@/components/CMS/Navigations/CMSNavSidebar.vue";
import Chart from "@/components/CMS/Dashboard/Chart.vue";
import Logs from "@/components/CMS/Dashboard/Logs.vue";

export default {
  name: "CMSDashboard",
  data() {
    return {
      status: [],
      chartValues: [],
      values: [],
      logs: [],
    };
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  components: {
    CMSNavTop,
    CMSNavSidebar,
    Chart,
    Logs,
  },
  created() {
    this.getOrdersStatus();
    this.getLastOrders();
    this.getLastLogs();
  },
  methods: {
    async getOrdersStatus() {
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/orders/getOrdersStatus.php`,
        data: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.status = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getLastOrders() {
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/orders/getLastOrders.php`,
        data: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.orders = res.data;
            this.orders.forEach((el) => {
              this.chartValues.push({
                sum: el.sum,
                order_no: el.order_no,
              });
            });
            this.chartValues.sort(
              (a, b) => b.order_no.split(" ")[1] - a.order_no.split(" ")[1]
            );
            this.chartValues.forEach((el) => {
              this.values.push(el.sum);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getLastLogs() {
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/logs/getLastLogs.php`,
        data: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.logs = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>