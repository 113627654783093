<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Data</th>
          <th class="text-left">Akcja</th>
          <th class="text-left">Użytkownik</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in logs" :key="item.id">
          <td>{{ item.datetime_created }}</td>
          <td>{{ item.action }}</td>
          <td>{{ item.author_name }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    logs: {
      type: Array,
      required: true,
    },
  },
};
</script>