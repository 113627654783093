<template>
  <v-app-bar app color="grey lighten-1">
    <div class="d-flex align-center">
      <v-img
        alt="Gecos Logo"
        class="shrink ml-2"
        contain
        src="@/assets/logo.png"
        transition="scale-transition"
        width="120"
      />
    </div>

    <v-spacer></v-spacer>

    <v-menu
      bottom
      :offset-y="true"
      origin="center center"
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          <span class="d-none d-sm-inline">Więcej</span>
          <v-icon class="ml-0 ml-sm-2">mdi-cog-outline</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-title>
            <v-btn color="info" block link :to="{ name: 'Shop' }">
              Sklep
              <v-icon class="ml-2"> mdi-home </v-icon>
            </v-btn></v-list-item-title
          >
        </v-list-item>
        <v-divider class="my-3"></v-divider>
        <v-list-item>
          <v-list-item-title>
            <v-btn color="secondary" block @click="logout">
              Wyloguj
              <v-icon class="ml-2"> mdi-logout-variant </v-icon>
            </v-btn></v-list-item-title
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-switch
              @click="changeDarkTheme"
              v-model="darkTheme"
              label="Ciemny motyw"
            ></v-switch>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: "CMSNavTop",
  data() {
    return {
      loading: false,
      darkTheme: false,
    };
  },
  props: {
    apiLink: {
      type: String,
    },
  },
  computed: {
    userType() {
      if (localStorage.userType) {
        return localStorage.userType;
      } else {
        return false;
      }
    },
  },
  created() {
    if (localStorage.themeDarkMode) {
      this.darkTheme = JSON.parse(localStorage.themeDarkMode);
    }
    this.$vuetify.theme.dark = this.darkTheme;
  },
  methods: {
    changeDarkTheme() {
      localStorage.themeDarkMode = JSON.stringify(this.darkTheme);
      this.$vuetify.theme.dark = this.darkTheme;
    },
    logout() {
      this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/auth/logout.php`,
        data: {
          token: localStorage.token,
        },
      });

      localStorage.removeItem("token");
      localStorage.removeItem("userType");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style>
.v-toolbar__content,
.v-toolbar__extension {
  padding-left: 8px;
  padding-right: 8px;
}
.sidebar-name {
  font-size: 18px;
  margin-bottom: 0;
}
.sidebar-role {
  font-size: 13px;
}
.avatar-img {
  object-fit: cover;
}
</style>