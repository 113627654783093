<template>
  <div class="sidebar">
    <v-navigation-drawer bottom permanent :expand-on-hover="navShow">
      <v-list nav dense>
        <v-list-item
          class="d-inline-flex d-lg-none"
          link
          @click="navShow = !navShow"
        >
          <v-list-item-icon>
            <v-icon>mdi-menu</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Menu</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'CMSDashboard' }">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'CMSOrders' }">
          <v-list-item-icon>
            <v-icon>mdi-cart-arrow-down</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Zamówienia</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'CMSCustomers' }">
          <v-list-item-icon>
            <v-icon>mdi-account-box-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Klienci</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'CMSUsers' }">
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Pracownicy</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'CMSProducts' }">
          <v-list-item-icon>
            <v-icon>mdi-package-variant-closed</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Produkty</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'CMSSale' }">
          <v-list-item-icon>
            <v-icon>mdi-sale</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Promocje</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'CMSLogs' }">
          <v-list-item-icon>
            <v-icon>mdi-math-log</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logi</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "CMSNavSidebar",
  data() {
    return {
      navShow: true,
    };
  },
};
</script>

<style>
.avatar-content {
  padding: 0 !important;
}
.sidebar {
  position: fixed;
  height: 100vh;
  z-index: 1;
}
</style>